import React from "react"
import styled from "styled-components"

import { FAQ } from "src/componentsV2/sections/FAQ"
import { ContainerPage, WrapperPage } from "src/componentsV2/ui/layout"

const HomeFAQContainer = styled(ContainerPage)`
  padding-top: 40px;
  @media (min-width: 768px) {
    padding-top: 80px;
  }
`

export const HomeFAQ = () => {
  return (
    <HomeFAQContainer data-loadable-component="home-faq">
      <WrapperPage>
        <FAQ id="faq" className="mx-auto max-w-md" />
      </WrapperPage>
    </HomeFAQContainer>
  )
}
