import React, { useState, ReactElement } from "react"
import styled from "styled-components"
import tw from "tailwind.macro"
import {
  IoIosArrowDropupCircle,
  IoIosArrowDropdownCircle,
} from "react-icons/all"

import { Title } from "src/componentsV2/sections/Text"

interface FAQDataItem {
  title: string
  content: ReactElement
  isCollapsed?: boolean
}

const FAQData: FAQDataItem[] = [
  {
    title: "Does AirMason integrate with my HRIS?",
    content: (
      <>
        We integrate with most HRIS platforms. If we’re unable to integrate with
        your HRIS platform, there are other easy ways to upload your employees
        into your AirMason account.
      </>
    ),
  },
  {
    title: "Do you offer support for drafting employee handbooks?",
    content: (
      <>
        <p>
          Yes! Our legal team can work with you to build a custom employee
          handbook from scratch or to update your existing employee handbook
          with current policies.
        </p>
      </>
    ),
  },
  {
    title:
      "Can I assign members of my team administrative access to the platform so they can edit my employee handbook?",
    content: (
      <>
        Absolutely! There is no limit to the number of collaborators you can
        have on your account. You can also configure permission levels for each
        collaborator.
      </>
    ),
  },
  {
    title: "Can our legal team review my employee handbook on your platform?",
    content: (
      <>
        You can give your legal team collaborator access to edit your employee
        handbook or send them a link to preview it.
        <ul style={{ listStyleType: "circle" }}>
          <li className="font-bold">
            How do you collect employee handbook signatures and will they hold
            up in litigation?
          </li>
          Yes, our handbook authorizations should be seen as valid if they were
          to be introduced in litigation. Generally speaking, digital signatures
          are widely recognized as legitimate in litigation, so long as they
          meet certain standards. These include intent and consent (the signer
          must have intended to sign), authentication (the identification of the
          signer should be verified), integrity of the document (it can’t be
          altered after signing without a new signature required), and an audit
          trail (timestamps, IP address information, etc.). All of these are
          satisfied by our signature system.
        </ul>
      </>
    ),
  },
  {
    title: "How do my team members access their employee handbook?",
    content: (
      <>
        Your team members can access your employee handbook through an SSO link
        or via username and password. The only people who can view your employee
        handbook on AirMason are members of your organization.
      </>
    ),
  },
  {
    title:
      "Can my team members view their employee handbook from their phones?",
    content: (
      <>
        All AirMason-hosted employee handbooks & documents are fully compatible
        with and accessible from smartphones, tablets, and computers!{" "}
      </>
    ),
  },
  {
    title:
      "Can I edit my employee handbook myself or do I need to ask AirMason? ",
    content: (
      <>
        You have the ability to make edits at any time to your employee handbook
        through our easy-to-use AirMason editor.
      </>
    ),
  },
  {
    title: "Can I run a report to see who hasn't signed my employee handbook?",
    content: (
      <>
        Our platform automatically collects this data, and you can download a
        report in CSV or PDF form for each version of your employee handbook or
        for each individual employee.
      </>
    ),
  },
  {
    title: "What other employee handbook analytics and data do you collect?",
    content: (
      <>
        AirMason collects a variety of employee handbook analytics, including
        time spent viewing, read receipts, total handbooks views, and specific
        page views.
      </>
    ),
  },
  {
    title:
      "Does AirMason provide automatic policy updates for municipalities, counties, and states?",
    content: (
      <>
        We provide automatic policy updates as an add-on service for all 50
        states (and the municipalities and counties within them), so your
        employee handbook is always current and compliant.
      </>
    ),
  },
  {
    title: "Does AirMason offer employee handbook translation services?",
    content: (
      <>
        Not directly, but we can refer you to a business that does excellent
        translation work.
      </>
    ),
  },
  {
    title:
      "Other than employee handbooks, what documents can I put on AirMason?",
    content: (
      <>
        Employee handbooks are just the tip of the iceberg! You can add any
        documents you’d like to your AirMason account—from culture books and
        benefit packages to SOPs, training/onboarding documents, and sales
        playbooks.
      </>
    ),
  },
  {
    title: "Can I download a PDF copy of my employee handbook?",
    content: (
      <>
        We always encourage our clients to view their employee handbooks in
        digital form. But if you need a PDF copy, we would be happy to export
        one for you.
      </>
    ),
  },
  {
    title: "Will I have help from your support team if questions arise?",
    content: (
      <>
        Without a doubt! You will work directly with an onboarding specialist to
        help launch your employee handbook and a member of our Customer Success
        team to assist with any questions or concerns. Our live chat feature is
        also a great way to reach our team.
      </>
    ),
  },
]

interface FAQContentProps {
  isCollapsed: boolean
}

const FAQTitle = styled.div`
  ${tw`text-blue-2`}
  font-family: Lato;
  font-size: 16px;
  letter-spacing: 0;
  padding: 15px 0px;
  display: flex;
  justify-content: space-between;

  .icon {
    margin-left: 15px;
    font-size: 20px;
    cursor: pointer;
    display: flex;
    align-items: center;
  }

  @media (min-width: 768px) {
    font-size: 25px;
    padding: 20px 0px 25px;

    .icon {
      margin-left: 30px;
      font-size: 23px;
    }
  }
`

const FAQContent = styled.div<FAQContentProps>`
  ${tw`text-gray-1`}
  overflow: hidden;
  letter-spacing: 0;
  margin-bottom: 25px;
  font-size: 18px;
  color: #676b87;
  line-height: 27px;
  font-weight: 400;

  p {
    margin-bottom: 15px;

    &:last-child {
      margin-bottom: 0px;
    }
  }

  ${props =>
    props.isCollapsed
      ? `
        max-height: 0;
        transition: max-height 0.5s cubic-bezier(0, 1, 0, 1), margin 0.5s cubic-bezier(0, 1, 0, 1);
        margin-bottom: 0;
      `
      : `
        max-height: 1000px;
        transition: max-height 1.5s ease-in-out;
      `};

  @media (min-width: 768px) {
    font-size: 18px;

    p {
      margin-bottom: 25px;
    }
  }
`

const FAQItem = styled(props => {
  const { item } = props
  const [isCollapsed, setIsCollapsed] = useState(true)

  function toggleCollapsed(): void {
    setIsCollapsed(!isCollapsed)
  }

  return (
    <div className={`faq-item ${props.className}`}>
      <FAQTitle className="faq-title cursor-pointer" onClick={toggleCollapsed}>
        <div className="title">{item.title}</div>
        <div className="icon">
          {isCollapsed ? (
            <IoIosArrowDropdownCircle />
          ) : (
            <IoIosArrowDropupCircle />
          )}
        </div>
      </FAQTitle>
      <FAQContent isCollapsed={isCollapsed} className="faq-content">
        {item.content}
      </FAQContent>
    </div>
  )
})`
  border-bottom: 1px solid rgb(151 151 151 / 36%);
`

const SectionTitle = styled(Title)`
  ${tw`max-w-xs mb-11 mx-auto`}
  margin-top: 20px;
  margin-bottom: 5px;

  @media (min-width: 768px) {
    text-align: center;
    margin-bottom: 25px;
    margin-top: 30px;
  }
`

export const FAQ = (props: React.HTMLProps<HTMLDivElement>) => {
  return (
    <div {...props}>
      <SectionTitle>Frequently asked questions</SectionTitle>
      {FAQData.map((item, idx) => (
        <FAQItem key={idx} item={item} />
      ))}
    </div>
  )
}
